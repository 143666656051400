<template>
    <div class="my_treat_page">
        <div class="name_info">
            <div class="name_num">
                <span class="left">{{ detailinfo.customername }}</span>
                <span class="right" v-if="detailinfo.appointmentnum">{{ detailinfo.appointmentnum }}</span>
            </div>
            <div class="account_info">
                <div class="sex_age_phone">
                    <span>{{ detailinfo.sex }} . {{ detailinfo.age }}岁</span>
                    <span v-if="detailinfo.phone">{{ detailinfo.phone }}</span>
                </div>
                <div class="account">
                    <span>工号：{{ dispname }}</span>
                </div>
            </div>

        </div>
        <div class="main_info ">
            <div class="info_line">
                <span>就诊诊所</span>
                <p>{{ detailinfo.clinicname || '--' }}</p>
            </div>
            <div class="info_line">
                <span>就诊时间</span>
                <p>{{ detailinfo.seetime }}</p>
            </div>
            <div class="info_line line">
                <span>开单医生</span>
                <p>{{ detailinfo.doctorname || '--' }}</p>
            </div>

            <div v-for="(orderitem, orderindex) in detailinfo.orderlist" :key="orderindex">
                <div v-for="(item, index) in orderitem.medicine" :key="index">

                    <div class="title top10" style="display:flex">
                        <van-checkbox
                            v-if="(sendstatus == 3 || sendstatus == 4 || sendstatus == 6) && medicineCheck.length > 0"
                            v-model="medicineCheck[orderindex][index]" :name="item.fid" shape="square" icon-size="20px"
                            style="margin-right:5px">
                            处方（{{ index + 1 }}）
                        </van-checkbox>

                    </div>
                    <div class="title top10">饮片药房（NO：{{ item.fcode }}）</div>
                    <van-steps :active="item.flowlist.findIndex((flowitem) => !flowitem.time)" active-color="#EAB03C">
                        <van-step v-for="(flowitem, flowindex) in item.flowlist" :key="flowindex">
                            {{ flowitem.statusname }}<br>{{ flowitem.time }}<br>{{ flowitem.dispname }}

                        </van-step>
                    </van-steps>

                    <div class="flex top10">
                        <div class="flex flex_item">
                            自煎剂量：{{ item.dosage }}
                        </div>
                        <div class="flex flex_item">
                            加工剂量：{{ item.replacedosage }}
                        </div>
                        <div class="flex flex_item">
                            制法：{{ item.preparationname }}
                        </div>
                    </div>
                    <div class="flex top10" v-if="item.maketime">
                        <div class=" flex_item" style="padding-left: 10px;">
                            配药日期：{{ item.maketime }}
                        </div>
                      
                       
                    </div>

                    <div class="flex top10" v-if="sendstatus == 0 || sendstatus == 3"
                        style="justify-content: space-between;">
                        <div>
                            <van-checkbox shape="square" v-if="sendstatus == 0" icon-size="20px" v-model="checked[index]"
                                @click="checkAll(index)">全选</van-checkbox>
                        </div>
                        <div>
                            <van-button size="small" v-if="sendstatus == 0 || sendstatus == 3" round
                                @click="changePreparation(item.dosage, item.replacedosage)">修改制法</van-button>
                            <van-button size="small" v-if="sendstatus == 0" round
                                @click="changeSplit(item.dosage, item.replacedosage)">拆方</van-button>
                        </div>

                    </div>
                    <van-checkbox-group v-if="sendstatus == 0" v-model="result[index]" :ref="'checkboxGroup' + index">
                        <div v-for="(drugitem, drugindex) in item.druglist" :key="drugindex"
                            :class="result && result[index] && result[index].includes(drugindex) ? 'activity_check_box' : 'check_box'">
                            <van-checkbox shape="square" :name="drugitem.did" icon-size="20px">
                                <div class="flex">
                                    <div class="flex_item_name">
                                        {{ drugitem.drugname }}
                                    </div>
                                    <div class="flex_item_name">
                                        {{ drugitem.druptypename }}
                                    </div>
                                    <div class="flex_item_name spec">
                                        规格：{{ drugitem.standard }}
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="flex_item_name spec">
                                        单位：{{ drugitem.unit }}
                                    </div>
                                    <div class="flex_item_name flex_item">
                                        单剂数量：{{ drugitem.onenum }}
                                    </div>
                                    <div class="flex_item_name flex_item">
                                        总量：{{ drugitem.totalnum }}
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="flex_item_name">
                                        单价：{{ drugitem.price }}
                                    </div>
                                    <div class="flex_item_name" style="flex:2">
                                        配药师：{{ drugitem.dispname }}
                                    </div>

                                </div>

                            </van-checkbox>
                        </div>
                    </van-checkbox-group>
                    <div v-else v-for="(drugitem, drugindex) in item.druglist" :key="drugindex"
                        :class="result && result[index] && result[index].includes(drugindex) ? 'activity_check_box' : 'check_box'">
                        <div class="flex">
                            <div class="flex_item_name">
                                {{ drugitem.drugname }}
                            </div>
                            <div class="flex_item_name">
                                {{ drugitem.druptypename }}
                            </div>
                            <div class="flex_item_name spec">
                                规格：{{ drugitem.standard }}
                            </div>
                        </div>
                        <div class="flex">
                            <div class="flex_item_name spec">
                                单位：{{ drugitem.unit }}
                            </div>
                            <div class="flex_item_name flex_item">
                                单剂数量：{{ drugitem.onenum }}
                            </div>
                            <div class="flex_item_name flex_item">
                                总量：{{ drugitem.totalnum }}
                            </div>
                        </div>
                        <div class="flex">
                            <div class="flex_item_name">
                                单价：{{ drugitem.price }}
                            </div>
                            <div class="flex_item_name" style="flex:2">
                                配药师：{{ drugitem.dispname }}
                            </div>

                        </div>


                    </div>
                    <div class="flex top10">
                        <div class="flex_item_name">
                            单剂中药总数量：{{ item.singleagenttotalnum }}
                        </div>
                        <div class="flex_item_name" style="text-align: end;">
                            单剂总重量：{{ item.singleagentnum }}
                        </div>

                    </div>
                    <div class="flex top10 line">
                        <div class="flex_item_name">
                            中药总数量：{{ item.singleagenttotalweight }}
                        </div>
                        <div class="flex_item_name" style="text-align: end;">
                            总重量：{{ item.singleagentweight }}
                        </div>

                    </div>
                </div>

                <div class="title top10">订单信息</div>
                <div class="info_line top10">
                    <span>自煎剂量</span>
                    <p>{{ orderitem.dosage }}剂</p>
                </div>
                <div class="info_line">
                    <span>加工剂量</span>
                    <p>{{ orderitem.replacedosage }}剂</p>
                </div>
                <div class="info_line">
                    <span>制法</span>
                    <p>{{ orderitem.preparation }}</p>
                </div>
                <div class="info_line">
                    <span>支付状态</span>
                    <p>{{ orderitem.paystatusname }}</p>
                </div>
                <div class="info_line">
                    <span>创建时间</span>
                    <p>{{ orderitem.createtime }}</p>
                </div>
                <div class="info_line">
                    <span>支付时间</span>
                    <p>{{ orderitem.paytime }}</p>
                </div>
                <div class="info_line">
                    <span>医嘱</span>
                    <p>{{ orderitem.doctorremark ? orderitem.doctorremark : '--' }}</p>
                </div>
                <div class="info_line">
                    <span>煎服法</span>
                    <p>{{ orderitem.decoctionmethod ? orderitem.decoctionmethod : '--' }}</p>
                </div>
                <div class="info_line">
                    <span>是否快递</span>
                    <p>{{ orderitem.isexpress ? '是' : '否' }}<span class="mini_bt" v-if="sendstatus != 5"
                            @click="modifyDelivery(orderitem.orderid, orderitem.isexpress)">修改</span></p>

                </div>
                <div class="info_line" v-if="orderitem.isexpress">
                    <span>收货地址</span>
                    <p style="text-align:left">{{ orderitem.addressinfo }} <span class="mini_bt" v-if="sendstatus != 5"
                            @click="modifyAddress">修改地址</span></p>

                </div>


            </div>

        </div>

        <div class="ctrl_bottom" v-if="sendstatus != 5">
            <div class="line">
                <div class="flex ">

                    <van-cell style="padding:0"
                        v-if="sendstatus == 0 || sendstatus == 3 || sendstatus == 4 || sendstatus == 6" :border="false"
                        :title="sendstatus == 0 || sendstatus == 3 ? '配药人：' : (sendstatus == 4 ? '加工人：' : '加工审核人：')"
                        @click="showPicker = true" is-link :value="dispenser" />
                    <van-cell style="padding:0" v-if="sendstatus == 2" :border="false" title="发药人"
                        @click="showPicker = true" is-link :value="dispenser" />

                </div>
                <div class="top10" style="display:flex"
                    v-if="sendstatus == 3 || sendstatus == 6 && detailinfo.isnotice == 0">
                    <van-checkbox v-model="isNotice" :name="1" shape="square" icon-size="20px" style="margin-right:5px">
                        通知客户取药
                    </van-checkbox>

                </div>
            </div>

            <!-- 这个是药方制法为代煎时，显示煲次，为中药饮片片剂时，则显示二次加工人 -->
            <van-field style="padding:0;" v-if="isdaijian && sendstatus == 4" v-model="cooktime" type="digit" label="煲次："
                placeholder="请输入煲次" />
            <div class="flex" style="justify-content:left">

                <van-cell style="padding:0" :border="false" v-if="isdrup && sendstatus == 4" title="二次加工人："
                    @click="showPicker = true; istwouser = true" is-link :value="twouser" />

            </div>
            <div class="flex top10" style="justify-content: right;">
                <van-button plain size="small" type="danger" round @click="$router.back()" class="danger">返回</van-button>
                <van-button v-if="sendstatus != 0 && sendstatus != 5" plain size="small" type="danger" round
                    @click="operate('BACKOUT')" class="danger">返回上一步</van-button>
                <van-button plain size="small" v-if="detailinfo.isnotice == 0" round class="warning"
                    @click="operate('NOTICE')">通知客户取药</van-button>
                <van-button plain size="small" v-if="sendstatus == 0" round class="warning"
                    @click="operate('PEIYAO')">配药</van-button>
                <!-- <van-button plain size="small" v-if="sendstatus == 0" @click="getClinicList" round
                class="warning">调整</van-button> -->
                <van-button plain size="small" v-if="sendstatus == 3" round class="warning"
                    @click="operate('AUDIT')">审核</van-button>
                <van-button plain size="small" v-if="sendstatus == 2" round class="warning"
                    @click="operate('FAYAO')">发药</van-button>
                <van-button plain size="small" v-if="sendstatus == 4" round class="warning"
                    @click="operate('PROCESS')">加工</van-button>
                <van-button plain size="small" v-if="sendstatus == 6" round class="warning"
                    @click="operate('PROCESS_AUDIT')">加工审核</van-button>
            </div>


        </div>
        <van-dialog v-model="showExpress" title="发货" z-index="8" show-cancel-button @confirm="operate('FAYAO')"
            :before-close="onBeforeClose">
            <van-cell :border="false" title="快递公司：" @click="showExpressList = true" :value="company" is-link />
            <van-field v-model="expressno" type="text" label="快递单号：" placeholder="请输入快递单号" />
        </van-dialog>
        <van-dialog v-model="showPreparation" title="修改制法" z-index="8" show-cancel-button @confirm="confirmPreparation"
            :before-close="onBeforeClosePreparation">

            <van-cell :border="false" title="制法：" @click="showPreparationList = true" :value="preparationName" is-link />
            <van-field v-model="dosage" type="digit" disabled label="自煎剂量：" placeholder="请输入自煎剂量" />

            <van-field v-model="replacedosage" type="digit" disabled label="代煎剂量：" placeholder="请输入代煎剂量" />
        </van-dialog>

        <van-dialog v-model="showSplit" title="拆方" z-index="8" show-cancel-button @confirm="confirmSplit"
            :before-close="onBeforeCloseSplit">

            <van-field v-model="dosage" type="digit" disabled label="原剂量：" placeholder="请输入自煎剂量" />

            <van-field v-model="replacedosage" type="digit" label="拆方剂量：" placeholder="请输入代煎剂量" />
            <div class="flex" style="justify-content:left;padding: 0.26667rem 0.42667rem;;">
                <span class="label_title" style="width: 2.8rem;">拆方配药日期：</span>
                <span class="date_info" @click="chooseCheckDay">{{ date || '请选择拆方配药日期' }}</span>
            </div>
        </van-dialog>
        <van-calendar v-model="showCalendar" :default-date="defaultDate" :show-confirm="false"
            @confirm="onConfirmCalendar" />
        <van-dialog v-model="showAddress" :title="title" z-index="8" show-cancel-button @confirm="onConfirmAddress">
            <van-cell @click="showaddressList = true" title="地址：" is-link :value="addressName" />

            <div class="flex" style="padding:10px;"> <van-button plain size="small" round
                    @click="getArea(); showAddAddress = true">新增地址</van-button></div>
        </van-dialog>


        <van-dialog v-model="showAddAddress" :close-on-click-overlay="true" title="新增地址" z-index="8"
            style="padding: 0px 10px 10px;" :showConfirmButton="false">
            <van-field v-model="name" label="姓名" required placeholder="请输入姓名" />
            <van-field v-model="tel" type="tel" required label="手机号" :error-message="errorMessage" @blur="checkPhone"
                placeholder="请输入手机号" />

            <van-field required v-model="fieldValue" is-link readonly label="地区" placeholder="请选择所在地区"
                @click="show = true" />
            <van-field v-model="address" label="详细地址" required placeholder="请输入详细地址" />
            <div class="flex"> <van-button round @click="onSave" style="width: 80%;">保存</van-button></div>

        </van-dialog>
        <van-dialog v-model="showClinic" title="调整" z-index="8" show-cancel-button>
            <van-cell :border="false" title="诊所：" @click="showClinicList = true" :value="clinicName" is-link />
        </van-dialog>
        <van-popup v-model="showaddressList" position="bottom">
            <van-picker title="选择地址" show-toolbar :columns="addressList" @confirm="onConfirmAddr"
                @cancel="showaddressList = false">
                <template #option="item">{{ item.addressinfo }}</template>
            </van-picker>
        </van-popup>
        <van-popup v-model="showClinicList" position="bottom">
            <van-picker title="请选择诊所" show-toolbar :columns="clinicList"
                :default-index="clinicList.findIndex((i) => i.clinicid == clinicId)" @cancel="showClinicList = false"
                @confirm="onConfirmClinic"><template #option="item">{{ item.clinicname
                }}</template>
            </van-picker>
        </van-popup>
        <van-popup v-model="showPreparationList" position="bottom">
            <van-picker title="请选择制法" show-toolbar :columns="preparationoPtion"
                :default-index="clinicList.findIndex((i) => i.value == preparation)" @cancel="showPreparationList = false"
                @confirm="onConfirmPreparation"><template #option="item">{{ item.text
                }}</template>
            </van-picker>
        </van-popup>
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker title="请选择处理人" show-toolbar :columns="doctorList"
                :default-index="doctorList.findIndex((doctorItem) => doctorItem.username == username)"
                @cancel="showPicker = false" @confirm="onConfirmDoctor"><template #option="item">{{ item.doctor
                }}</template>
            </van-picker>
        </van-popup>
        <van-popup v-model="showExpressList" round position="bottom">
            <van-picker title="请选择快递公司" show-toolbar :columns="expressList" :default-index="0"
                @cancel="showExpressList = false" @confirm="onConfirmExpress"><template #option="item">{{ item.expressname
                }}</template>
            </van-picker>
        </van-popup>

        <van-popup v-model="show" round position="bottom">
            <van-cascader v-model="cascaderValue" title="请选择所在地区" :options="options" @close="show = false"
                @change="onChange" @finish="onFinish"
                :field-names="{ text: 'name', value: 'code', children: 'children' }" />

        </van-popup>
    </div>
</template>

<script>
import {
    wxdispensedetail, getwxqryclinic, wxtriagedictionarydataqry, wxdispensing, wxmodifypreparation, wxtakedosage,
    wxtriagedoctorroomqry, wxaddressqry, wxaddaddress, wxareaqry, wxmodifyexpress, wxexpressmatchinfoqry
} from '@/api/medical'
import { mapGetters } from 'vuex'
import { Toast, Dialog } from 'vant'
import area from '@/utils/area'
export default {
    computed: {
        ...mapGetters([
            'username', 'dispname', 'clinicid', 'isrefresh'
        ]),

    },
    data() {
        return {
            detailinfo: {},
            // list: [],
            fid: '',
            active: 1,
            result: [],
            checked: [],
            date: '',
            doctorList: [],
            dispenser: '',
            isdrup: false,
            preparationoPtion: [

            ],
            preparation: 0,
            preparationName: '',
            sendstatus: 0,
            value: 0,
            showPreparation: false,
            dosage: '',
            replacedosage: '',
            showCalendar: false,
            defaultDate: new Date(),
            showSplit: false,
            showAddress: false,
            addressId: 0,
            addressList: [],
            showAddAddress: false,
            showaddressList: false,
            showClinic: false,
            showClinicList: false,
            showPreparationList: false,
            areaList: area,
            searchResult: [],
            addressName: '',
            clinicId: 0,
            clinicName: '',
            clinicList: [],
            sendstatus: 0,
            medicineCheck: [],
            showPicker: false,
            dispenserName: '',
            show: false,
            cascaderValue: '',
            fieldValue: '',
            options: [],
            address: '',
            tel: '',
            name: '',
            errorMessage: '',
            title: '',
            province: [],
            showExpressList: false,
            showExpress: false,
            expressList: [],
            company: '',
            companyid: '',
            expressno: '',
            twouser: '',
            twouserName: '',
            isdaijian: false,
            cooktime: 1,
            isNotice: 0
        }
    },

    mounted() {
        this.$store.dispatch('hideOrShowNav', false)
        this.fid = this.$route.query.fid
        this.sendstatus = this.$route.query.sendstatus
        this.twouser = this.dispname
        this.dispenser = this.dispname
        this.dispenserName = this.username
        this.twouserName = this.username
        this.getDetailInfo()
        this.getdoctorroomList()
    },
    beforeRouteLeave(to, from, next) {
        sessionStorage.setItem('date', this.$route.query.date)
        next()
    },
    methods: {
        getCurDate() {
            const nowDate = new Date();
            this.formatDate(nowDate)
        },

        formatDate(d) {
            const curDate = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                date: d.getDate(),
            }
            const newmonth = curDate.month >= 10 ? curDate.month : '0' + curDate.month
            const day = curDate.date >= 10 ? curDate.date : '0' + curDate.date
            this.date = curDate.year + '-' + newmonth + '-' + day
        },
        // 获取发药信息
        getDetailInfo() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            let data = {
                username: this.username,
                fid: this.fid,
                clinicid: this.clinicid,
                sendstatus: this.sendstatus
            }
            wxdispensedetail(data).then(response => {
                Toast.clear()
                if (response.response_body == '' || !response.response_body) {
                    Toast({
                        message: '该发药单已处理，请刷新页面后查看',
                        forbidClick: true,
                    })
                }
                this.detailinfo = response.response_body
                this.medicineCheck = []
                this.detailinfo.orderlist.forEach((orderitem, orderindex) => {
                    this.medicineCheck.push([])
                    orderitem.medicine.forEach((mitem, index) => {
                        this.medicineCheck[orderindex].push(true);
                        mitem.druglist.forEach((ditem) => {
                            if (ditem.druptype == 1 && mitem.preparationname == '片剂') {
                                this.isdrup = 1
                            }
                            if (mitem.preparationname == '代煎') {
                                this.isdaijian = 1
                            }
                        })
                    })


                })

            })


        },

        checkAll(index) {

            this.$refs['checkboxGroup' + index][0].toggleAll(this.checked[index])
        },
        formatDate(d) {
            const curDate = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                date: d.getDate(),
            }
            const newmonth = curDate.month >= 10 ? curDate.month : '0' + curDate.month
            const day = curDate.date >= 10 ? curDate.date : '0' + curDate.date
            this.date = curDate.year + '-' + newmonth + '-' + day
            this.defaultDate = new Date(this.date);
        },
        chooseCheckDay() {
            this.showCalendar = true
        },
        onConfirmCalendar(date) {
            this.showCalendar = false
            this.formatDate(date);
        },
        modifyDelivery(orderid, isexpress) {
            if (isexpress) {
                this.$dialog.confirm({
                    title: null,
                    message: ' 是否取消发送快递？',
                })
                    .then(() => {
                        let data = {
                            username: this.username,
                            orderid: orderid,
                            isexpress: 0

                        }
                        wxmodifyexpress(data).then(response => {
                            Toast.success({
                                message: '操作成功',
                                forbidClick: true,
                            })
                            setTimeout(() => {
                                this.getDetailInfo()
                            }, 2000)
                        })
                    })
            } else {
                this.title = '修改是否快递';
                this.getAddr()
                this.showAddress = true
                this.orderid = orderid
            }
        },
        modifyAddress() {
            this.title = '修改地址';
            this.getAddr()
            this.showAddress = true

        },
        onConfirmAddress() {
            let data = {
                username: this.username,
                orderid: this.orderid,
                isexpress: 1,
                addressid: this.addressId,
                addressinfo: this.addressName

            }
            wxmodifyexpress(data).then(response => {
                Toast.success({
                    message: '操作成功',
                    forbidClick: true,
                })
                setTimeout(() => {
                    this.getDetailInfo()
                }, 2000)
            })
        },
        onChange({ value, selectedOptions, tabIndex }) {
            let data = {
                username: this.username,
                qrytype: tabIndex + 1,
                code: value
            }

            wxareaqry(data).then(response => {

                if (tabIndex == 0) {
                    let index = this.options.findIndex((item) => item.code == value)
                    this.options[index].children = []
                    response.response_body.list.forEach((item) => {
                        this.options[index].children.push({ "code": item.code, "name": item.name, children: [] })
                    })
                } if (tabIndex == 1) {
                    let index = -1
                    let cindex = -1
                    this.options.forEach((item, myindex) => {
                        let pcindex = item.children.findIndex((citem) => citem.code == value)
                        if (pcindex > -1) {
                            index = myindex
                            cindex = pcindex
                            return
                        }
                    })

                    this.options[index].children[cindex].children = response.response_body.list
                }

            })
        },
        onFinish({ selectedOptions }) {
            this.show = false;
            this.fieldValue = selectedOptions.map((option) => option.name).join('/');
            this.province = selectedOptions.map((option) => option.code)
        },

        getArea() {
            let data = {
                username: this.username,
                qrytype: 0,
                code: 0
            }
            wxareaqry(data).then(response => {
                this.options = []

                response.response_body.list.forEach((item) => {
                    this.options.push({ "code": item.code, "name": item.name, children: [] })
                })

            })
        },
        onBeforeClose(action, done) {
            // new Promise((resolve) => {
            if (action === 'confirm') {
                if (this.companyid == '' || this.expressno == '') {
                    done(false);
                }
                done(true);
            } else {
                // 拦截取消操作
                done(true);
            }

        },
        onBeforeClosePreparation(action, done) {
            // new Promise((resolve) => {
            if (action === 'confirm') {
                if (this.preparation == '' || this.replacedosage == '' || this.dosage == '') {
                    done(false);
                }
                done(true);
            } else {
                // 拦截取消操作
                done(true);
            }

        },
        onBeforeCloseSplit(action, done) {
            // new Promise((resolve) => {
            if (action === 'confirm') {
                if (this.date == '' || this.replacedosage == '') {
                    done(false);
                }
                done(true);
            } else {
                // 拦截取消操作
                done(true);
            }

        },
        onSave() {
            if (this.tel == '') {
                Toast.fail({
                    message: '请输入收货人',
                    forbidClick: true,
                })
                return
            }
            if (this.tel == '') {
                Toast.fail({
                    message: '请输入收货号码',
                    forbidClick: true,
                })
                return
            }
            if (this.errorMessage != '') {
                Toast.fail({
                    message: '手机号格式错误',
                    forbidClick: true,
                })
                return
            }
            if (this.province.length < 3) {
                Toast.fail({
                    message: '请选择区域',
                    forbidClick: true,
                })
                return
            }
            if (this.address == '') {
                Toast.fail({
                    message: '请输入详细地址',
                    forbidClick: true,
                })
                return
            }
            let data = {
                username: this.username,
                userid: this.detailinfo.userid,
                receiver: this.name,
                tel: this.tel,
                province: this.province[0],
                city: this.province[1],
                area: this.province[2],
                address: this.address
            }

            wxaddaddress(data).then(response => {
                // this.doctorList = response.response_body.doctorlist
                Toast.success({
                    message: '新增成功',
                    forbidClick: true,
                })
                this.showAddAddress = false;
                this.getAddr();
            })

        },
        checkPhone() {
            // 简单的正则表达式用于验证手机号
            if (!/^1[3-9]\d{9}$/.test(this.tel)) {
                this.errorMessage = "手机号格式错误";
            } else {
                this.errorMessage = "";
            }
        },
        onConfirmAddr(obj) {
            console.log(obj);
            this.addressName = obj.addressinfo
            this.addressId = obj.addressid
            this.showaddressList = false
        },
        onConfirmClinic(obj) {
            // this.preparationName = obj.text
            // this.preparation = obj.value
            // this.showClinicList = false

        },
        onConfirmPreparation(obj) {
            this.preparationName = obj.text
            this.preparation = obj.value
            this.showPreparationList = false
            // 如果不是自煎，则需要将自煎剂量和加工剂量进行调换，自煎时，只有自煎剂量，其他制法时，只有加工剂量
            if (this.preparationName == '自煎') {

                this.dosage = this.dosage * 1 + this.replacedosage * 1
                this.replacedosage = 0
            } else {
                this.replacedosage = this.dosage * 1 + this.replacedosage * 1
                this.dosage = 0
            }
        },
        onConfirmExpress(obj) {
            this.company = obj.expressname
            this.companyid = obj.expresscode
            this.showExpressList = false
        },
        getClinicList() {
            let data = {
                username: this.username,
            }
            getwxqryclinic(data).then(response => {
                this.clinicList = response.response_body.cliniclist
                this.showClinic = true
            })
        },
        changePreparation(dosage, replacedosage) {
            this.getType('PREPARATION_TYPE', "1,2,3,4,5,6");
            this.showPreparation = true
            this.replacedosage = replacedosage
            this.dosage = dosage
            this.preparationName = ''
            this.preparation = ''
        },
        changeSplit(dosage, replacedosage) {
            // console.log(dosage,replacedosage);
            this.replacedosage = ''
            this.dosage = dosage * 1 + replacedosage * 1;
            this.getCurDate();
            this.showSplit = true

        },

        getType(typeName, itemcode) {
            let data = {
                username: this.username,
                dictcode: typeName,
                itemcode: itemcode
            }
            wxtriagedictionarydataqry(data).then(response => {

                let list = response.response_body.list.map((item) => {

                    return { text: item.itemname, value: item.itemcode }
                })
                this.preparationoPtion = list
            })
        },
        // 查询医生
        getdoctorroomList() {
            let data = {
                username: this.username,
                clinicid: sessionStorage.ClinicId
            }
            wxtriagedoctorroomqry(data).then(response => {
                this.doctorList = response.response_body.doctorlist
            })
        },
        onConfirmDoctor(val) {
            if (this.istwouser) {
                this.twouser = val.doctor
                this.twouserName = val.username
            } else {
                this.dispenser = val.doctor
                this.dispenserName = val.username
            }

            this.showPicker = false
        },
        confirmPreparation() {
            if (this.preparation == '') {
                Toast({
                    message: '请选择制法！',
                    forbidClick: true,
                })
                return
            }
            if (this.replacedosage == '') {
                Toast({
                    message: '请输入代煎剂量！',
                    forbidClick: true,
                })
                return
            }
            if (this.dosage == '') {
                Toast({
                    message: '请输入自煎剂量！',
                    forbidClick: true,
                })
                return
            }
            let data = {
                username: this.username,
                fid: this.fid,
                dosage: this.dosage,
                replacedosage: this.replacedosage,
                preparation: this.preparation,
            }
            wxmodifypreparation(data).then(i => {
                this.getDetailInfo()
            })
        },
        confirmSplit() {
            if (this.replacedosage == '') {
                Toast({
                    message: '请输入拆方剂量！',
                    forbidClick: true,
                })
                return
            }
            if (this.date == '') {
                Toast({
                    message: '请选择拆方日期！',
                    forbidClick: true,
                })
                return
            }
            let data = {
                username: this.username,
                fid: this.fid,
                dosage: this.dosage,
                rdosage: this.replacedosage,
                date: this.date
            }
            wxtakedosage(data).then(i => {
                this.getDetailInfo()
            })
        },
        getAddr() {
            console.log(this.detailinfo)
            let data = {
                username: this.username,
                userid: this.detailinfo.userid
            }
            wxaddressqry(data).then(response => {
                this.addressList = response.response_body.list
            })
        },

        operate(type) {
            let data = {}
            if (type == 'PEIYAO' && this.result.length == 0) {
                Toast({
                    message: '请选择药品！',
                    forbidClick: true,
                })
                return
            }
            if (type == 'FAYAO' && this.detailinfo.orderlist.findIndex((item) => item.isexpress == 1) > -1) {
                if (this.showExpress == false) {
                    this.getExpressList()
                    this.showExpress = true
                    return
                } else {
                    if (this.companyid == '') {
                        Toast({
                            message: '请选择快递公司！',
                            forbidClick: true,
                        })
                    }
                    if (this.expressno == '') {
                        Toast({
                            message: '请输入快递单号！',
                            forbidClick: true,
                        })
                    }
                    data.company = this.companyid
                    data.expressno = this.expressno
                }

            }
            let list = []
            this.detailinfo.orderlist.forEach((orderitem, orderindex) => {


                orderitem.medicine.map((item, index) => {
                    let didList = []

                    if (this.result[index]) {
                        didList = this.result[index].map((item) => { return { did: item } })
                    }



                    if (type == 'PEIYAO' || type == 'BACKOUT' || type == 'NOTICE' || type == 'FAYAO') {
                        list.push({ fid: item.fid, druglist: didList })
                    } else {
                        if (this.medicineCheck[orderindex][index]) {
                            if (type == 'PROCESS') {

                                if (this.daijian) {
                                    list.push({ fid: item.fid, druglist: didList, cooktime: this.cooktime })
                                } else {
                                    list.push({ fid: item.fid, druglist: didList })
                                }
                                if (this.isdrup) {
                                    data.twouser = this.twouserName
                                }
                            } else {
                                list.push({ fid: item.fid, druglist: didList })
                            }
                        }



                    }
                })
            })
            if (list.length == 0) {
                Toast({
                    message: '请选择处方！',
                    forbidClick: true,
                })
                return
            }


            data.username = this.username
            data.oprtype = type
            data.list = list
            data.curuser = this.dispenserName


            wxdispensing(data).then(response => {
                Toast.success({
                    message: '操作成功',
                    forbidClick: true,
                })
                if (this.isNotice && type != 'NOTICE') {
                    this.operate('NOTICE')
                    return
                }
                if (response.response_body.status == 0 && this.$route.query.sendstatus == 0) {
                    this.getDetailInfo();
                } else {
                    setTimeout(() => {
                        this.$route.query.sendstatus = response.response_body.status
                        sessionStorage.setItem('qrystatus', this.$route.query.sendstatus)
                        this.$router.back()
                    }, 2000)
                }


            })
        },
        getExpressList() {
            let data = {
                username: this.username
            }
            wxexpressmatchinfoqry(data).then(response => {
                this.expressList = response.response_body.list

            })
        }

    },
}
</script>

<style lang="scss" scoped>
:deep .van-steps__items {
    height: 1rem;
    width: max-content;
}

:deep .van-step__title {
    text-align: center;
    margin-left: 0px;
    font-size: 11px;
    max-width: 1.5rem;
}

:deep .van-step__circle-container {
    top: -0.2rem !important;
}

:deep .van-step__line {
    top: -0.2rem !important;
}

:deep .van-steps--horizontal {
    margin-top: 0.5rem;
    padding: 0.37667rem 0 0;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.flex_item {
    flex: 1;
    color: #07c160 !important;
}

.flex_item_name {
    flex: 1;
    color: #333;
    padding: 5px 0px;
    font-size: 12px;
}

.spec {
    color: rgb(177, 175, 175) !important;
}

.line {
    border-bottom: 1px solid #dfdcdc;
    padding-bottom: 8px;
}

:deep .van-button--round {
    background-color: #CF8C57;
    padding: 5px 10px;
    // height: 38px;
    text-align: center;
    line-height: 38px;
    color: #fff;
    border-radius: 21px;
    margin: 0px 5px;
}

:deep .van-button--warning {
    background-color: #bfbaba !important;
    border: 0.02667rem solid #d3d3d3
}

.top10 {
    margin-top: 10px;
}

:deep .van-checkbox {
    align-items: start;
}

:deep .van-checkbox__label {
    width: 100%;
}

.check_box {
    margin-top: 10px;
    background-color: #ededed;
    padding: 10px;
    border-radius: 10px;

}

.activity_check_box {
    margin-top: 10px;
    background: #e4c1a663 !important;
    padding: 10px;
    border-radius: 10px;
}

.title {
    font-size: 0.42667rem;
    color: #333333;
    font-weight: 600;
}

:deep .van-dropdown-menu__bar {
    box-shadow: unset;
    height: 1rem;
}

.mini_bt {
    color: #5C7BFF !important;
    margin-left: 5px;
    float: right;
    text-align: right;
}

:deep .van-dropdown-item__content {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.info_line {
    align-items: start !important;

}

.ctrl_bottom {
    justify-content: end !important;
    z-index: 6;
    height: unset;
    display: block;
}

.warning {
    color: #EAB03C !important;
    border: 0.02667rem solid #EAB03C !important;
    margin-bottom: 5px;
}

.danger {
    color: red !important;
    border: 0.02667rem solid red !important;
    margin-bottom: 5px;
}

:deep .van-popup--top {
    // position: fixed;
    top: 9%;
    left: 50%;
    width: 8.53333rem;
    -webkit-transform: translate3d(-50%, -50%, 0);
}


:deep .flex {
    .van-popup--top {
        top: 0;
        left: 0% !important;
        width: 100% !important;
    }

    .van-dropdown-item__content {
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}

.dropdown-menu {
    height: 25px;
}

:deep .van-dropdown-menu__item {
    height: 25px;
}

.label_title {
    width: 2.3rem;
    font-size: 0.37333rem;
    line-height: 0.64rem;
    color: #646566;
}

.right_bt {
    margin-left: 20px;
}

:deep .van-cell__left-icon,
.van-cell__right-icon {
    line-height: 0.64rem !important;
}

// :deep .van-cell__title {
//     flex: unset;
//     width: 1.2rem;
// }

// .van-cell__value {
//     flex: unset;
//     width: 3.7rem;
//     text-align: left;
// }
.van-cell {
    justify-content: space-between;
}

.van-cell::after {
    border-bottom: 0;
}

:deep .van-step {
    flex: unset;
    width: 2.5rem;
}

.van-steps {
    overflow: auto;
    height: 2rem;
}

// 步骤条样式改造
:deep .van-step--horizontal:last-child .van-step__circle-container {
    right: 2rem;
}

:deep .van-step--horizontal:last-child {
    right: -2rem;
}

:deep .van-step:last-child {
    width: 2.6rem !important;
}

:deep .van-step:first-child {
    width: 2.8rem !important;
}

:deep .van-step--horizontal:first-child .van-step__circle-container {
    left: 0.5rem;
    ;
}

:deep .van-step--horizontal:first-child .van-step__line {
    left: 0.5rem;
}

// 横向滚动条
::-webkit-scrollbar-track-piece {
    background-color: #f1f1f1;
    border-left: 1px solid #e6ebf5;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}

::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    background-clip: padding-box;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    min-height: 28px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #787878;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;

}

:deep .van-picker-column__item {
    word-break: break-all;
}

:deep .van-cell__value {
    word-break: break-all;
}

.my_treat_page {
    margin-bottom: 0px;
    padding-bottom: 5rem;
}

:deep .van-cell--clickable {
    .van-cell__title {
        flex: 1;
    }

    .van-cell__value {
        flex: 4;
    }
}
</style>